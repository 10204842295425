.halfPanel {
  padding-left: 5px;
  padding-right: 5px;
  width: calc(50% - 4px);
  display: inline-block;
  vertical-align: top;
  min-height: 370px;
}

.first{
  margin-right: 8px;
}

.loading{
  min-height: 250px;
  padding-top: 125px;
}

.rightBorder {
  border-right: 1px solid #333;
  border-radius: 0px;
  width: calc(50% - 8px)
}

.panel{
  padding-left: 5px;
  padding-right: 5px;
  .title{
    margin-bottom: 0px;
    color: #66707d;
    span{
      color: #128292;
    }
  }
}

.fullpanel{
  padding-left: 0px;
  padding-right: 0px;
}

.title{
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #3C4654;
  &:nth-of-type(2){
    margin-top: 30px;
  }
}

.right{
  text-align: right;
  padding-right: 20px;
  color: #66707d;
    span{
      color: #128292;
    }
}