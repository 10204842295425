@import '~app/variables';

.root {
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 24px;
  padding-right: 14px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.title {
  color: $color-blue-dark;
  font-size: 16px;
  font-weight: 400;
}

.body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  height: calc(100% - 50px);
}

.selected {
  position: absolute;
  bottom: 2px;
  font-size: 12px;
  font-style: italic;
  color: gray;
}

.oneline {
  display: flex;
  align-items: center;
}

.fullWidth {
  flex-grow: 1;
}

.ml {
  margin-left: 10px;
}

.clickable {
  display: inline-block;
  margin-left: 31px;
  cursor: pointer;
}

.iconCarret {
  display: inline-block;
  vertical-align: middle;
  width: 9px;
  height: 6px;
  margin-left: 10px;
}

.status {
  font-size: 14px;

  .complete {
    font-weight: bold;
    color: $color-green-bright;
  }

  .processing {
    font-style: italic;
  }
}

.legend {
  font-size: 10px;
  color: #666;
  margin: 0 8px;
}

.actions {
  display: flex;

  button {
    margin-left: 6px;
    min-width: 100px;
  }
}
