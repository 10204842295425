@import '~app/variables';

.wrapToast {
  padding: 25px 10px;
}

.tabWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.key {
  color: $color-blue-darkest;
  font-size: 12px;
  line-height: 20px;
}

.value {
  color: $color-grey;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}
