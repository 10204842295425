@import '~app/variables';

.flowFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 12px 20px;
}

.caption {
  color: $color-blue-dark;
  font-size: 12px;
  line-height: 20px;
}

.filter {
  display: flex;

  :global {
    label + div {
      display: flex;
    }

    input[type="text"] {
      width: 30px;
      margin-left: 15px;
    }

    label {
      color: $color-grey-light;
      font-size: 12px;
      float: right;
      line-height: 26px;
      margin: 0 0 0 5px;
    }
  }
}

.tabWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 240px);
}

.key {
  color: $color-blue-darkest;
  font-size: 12px;
  line-height: 20px;
}

.value {
  color: $color-grey;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

.iconUp,
.iconDown {
  height: 35px;
  width: 40px;
  margin-right: 30px;
}

.iconUp use {
  fill: $color-green;
}

.iconDown use {
  fill: $color-red;
}

.totalRow {
  border-top: none !important;
}

.totalKey {
  color: $color-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.totalValue {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
