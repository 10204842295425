@import '~app/variables';

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 50px;

  .leftSide {
    width: $left-side-width;
    min-width: $left-side-width;
    flex-shrink: 0;
  }

  .rightSide {
    flex: 1;
    width: calc(100% - 285px);
  }
}

:root {
  a.btnNewCampaign {
    margin: 0 auto;
    display: block;
    width: 130px;
    padding: 4px 10px;
  }
}
.container:first-child {
  display: none !important;
}