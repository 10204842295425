@import '~app/variables';

$color-border: #d6d6d6;


.info {
  width: 400px;

  .details {
    color: $color-grey-1;
    font-size: 11px;
    line-height: 18px;
    display: flex;

    .image {
      $size: 100px;

      height: $size;
      overflow: hidden;
      border-radius: 4px;
      flex-shrink: 2;

      img {
        border-radius: 4px;
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .info {
      display: flex;
    }

    .address {
      padding: 3px 0;
    }

    .left,
    .right {
      padding: 0 5px;
    }

    .left {
      width: 155px;
      min-width: 155px;

      .price {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: $color-grey;
      }
    }

    .right {
      align-self: center;
      border-left: 2px solid $color-border;
      width: 196px;
    }
  }

  .apartment {
    margin: 18px 0;
    display: flex;
    justify-content: center;

    .apartmentItem {
      padding: 0 5px;
      border-left: 2px solid $color-border;
      display: flex;

      &:first-child {
        border-left: none;
      }
    }

    .centered {
      align-self: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .value,
    .caption {
      color: $color-grey;
      text-align: center;
    }

    .value {
      font-weight: 500;
      width: 100%;
      line-height: 22px;
    }

    .caption {
      font-size: 11px;
    }

    .captionInline {
      font-size: 11px;
      color: $color-grey;
      text-align: left;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .chartInfo {
    display: flex;
    margin-bottom: 10px;

    .chart {
      padding: 0;
    }

    .chartLegends {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      color: $color-grey-1;
      font-size: 11px;
      line-height: 18px;
      padding-left: 20px;
      width: 185px;
    }

    .chartLegendItem {
      width: 100%;
      display: flex;
      margin: 1px 0;
    }

    .icon {
      width: 26px;
      margin-right: 10px;
      text-align: center;
    }

    .iconMeter {
      width: 26px;
      height: 16px;
    }

    .iconArrow {
      width: 16px;
      height: 16px;

      use {
        fill: $color-green;
      }

      &.red use {
        fill: $color-red;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    a,
    button {
      width: 115px;
    }
  }

  .tiles {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 8px;
    flex-wrap: wrap;

    .tile {
      display: flex;
      align-items: center;
      justify-content: center;

      .wrapper {
        text-align: center;

        .value {
          font-size: 11px;
          font-weight: bold;
          line-height: 32px;
          color: $color-green;
          background: $color-dirty-white;
          display: block;
          width: 72px;
          height: 32px;
          margin-bottom: 5px;
          border-radius: 6px;
        }

        .title {
          font-size: 11px;
          color: $color-grey-1;
          display: block;
          width: 100%;
          font-style: italic;
        }
      }
    }
  }
}
