.header {
    align-self: stretch;
    display: flex;
    text-align: left;
    background-color: #fff;
    border-bottom: 1px solid #DFE1E4;
    padding: 20px 20px;
    height: 78px;

    .backRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 58px;
        padding-left: 10px;
        padding-right: 5px;

        .back {
            height: 36px;
            width: 24px;
        }
    }

    .title {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
        color: #3C4654;
        padding: 0px;
        margin: 0px;
    }

    .subTitle {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 14px;
        color: #66707D;
        text-transform: uppercase;
        padding: 0px;
        margin: 0px 0px 4px 0px;
        line-height: 14px;
    }
}

.pageTitle{
    font-family: 'Inter';
    text-align: center;
    margin: 36px 0px 28px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title{
        font-weight: 600;
        font-size: 36px;
        color: #3C4654;
        letter-spacing: -2px;
        line-height: 36px;
        margin-bottom: 0px;
    }

    .subTitle{
        font-weight: 500;
        font-size: 14px;
        color: #66707D;
    }
}