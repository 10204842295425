@import '~app/mixins';

.mailingLabels {

  .layouts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0;

    .layout {
      cursor: pointer;
      width: 32%;
      border: 1px solid $color-grey-light;
      border-radius: 8px;
      padding: 8px;
      margin-bottom: 12px;

      .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;

        .image {
          max-height: 275px;
        }
      }

      .name {
        text-align: center;
        font-weight: bold;
        padding: 10px;
        color: #666;
      }

      &.selected {
        background-color: #f3e3a1;
        border-width: 2px;
        padding: 7px;
      }
    }
  }

  .option {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    > div {
      margin: 0 12px;
    }

    label {
      color: #666;
      font-size: 12px;
      margin: 6px 6px 0 0;
    }

    .info {
      color: $color-grey;
      font-style: italic;
      margin: 6px 0 0 8px;
      font-size: 11px;
    }

    input[type="text"] {
      width: 50px;
    }
  }

  .footer {
    text-align: center;

    button {
      min-width: 130px;

      & + button {
        margin-left: 30px;
      }
    }
  }
}
