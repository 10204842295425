@import '~app/variables';

.header {
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;
  color: $color-blue-dark;
}

.body {
  margin: 25px 0 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
