@import '~app/variables';

.newContact {
  width: 100%;

  .header,
  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .btn {
    min-width: 140px;
    margin-left: 10px;
  }

  .header {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 25px 20px;

    .title {
      color: $color-blue-dark;
      font-size: 24px;
      line-height: 32px;
    }

    .companyTitle {
      color: $color-dove-gray;
      font-size: 16px;
    }

    .legend {
      font-size: 13px;
      color: #666;
      margin: 0 8px;
    }
  }

  .body {
    padding: 0 20px 35px;
    height: calc(100vh - 105px);
    overflow-x: hidden;
    overflow-y: auto;

    .container {
      margin: 0;
      width: 100%;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      background-color: $color-white;
      padding: 20px 20px 0;
      position: relative;
      display: flex;

      select,
      .input {
        background: $color-white;
        width: 100%;
        padding: 5px;
        color: #4a4a4a;
        height: 42px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 0 0 #ececec;
        font-size: 14px;
        line-height: 20px;
        padding-left: 15px;
      }

      .fields {
        .row {
          $offset: 4px;

          display: flex;
          margin-left: -$offset;
          margin-right: -$offset;
          margin-bottom: 9px;
        }

        .checkRow {
          margin: -10px 0 20px;
        }

        .xs,
        .sm,
        .sm2,
        .md,
        .lg,
        .xl {
          margin-left: 4px;
          margin-right: 4px;
        }

        .xs {
          width: 100px;
        }

        .sm {
          width: 130px;
        }

        .sm2 {
          width: 156px;
        }

        .md {
          width: 180px;
        }

        .lg {
          width: 320px;
        }

        .xl {
          width: 508px;
        }
      }

      .centered {
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
      }

      .label {
        color: #4a4a4a;
        font-size: 14px;
        line-height: 20px;
      }

      .radio {
        margin-left: 44px;
        margin-right: 4px;
      }

      .radioRow {
        display: flex;
        height: 42px;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
      }

      .appended {
        margin-top: 15px;
        margin-left: 4px;

        .appendHeader {
          color: #4a4a4a;
          font-size: 18px;
          padding-bottom: 5px;
          border-bottom: 1px solid #aaa;
          margin-bottom: 15px;
          width: 100%;
        }

        .email {
          width: 320px;
          margin-right: 10px;
        }

        .phone {
          width: 155px;
          margin-right: 10px;
        }

        .row {
          max-width: 1000px;
          flex-wrap: wrap;
        }
      }

      .items {
        margin-left: 22px;

        .icon {
          width: 22px;
          height: 22px;
          cursor: pointer;
        }

        .itemsHeader {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;

          .itemsTitle {
            font-weight: bold;
            color: #66707d;
            font-size: 14px;
          }

          svg {
            fill: green;
            margin-top: -8px;
            margin-left: 10px;
          }
        }

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &.phone {
            width: 370px;
          }

          &.email {
            width: 350px;
          }

          .type {
            width: 100px;
          }

          .dnc {
            width: 115px;
          }

          .inputContainer {
            flex: 1;
          }

          > svg {
            fill: red;
          }

          > * {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

