@import '~app/variables';

.root {
  padding: 0;
  align-items: flex-end;

  > div {
    margin-right: 0;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.caption {
  font-size: 16px;
  line-height: 18px;
  color: $color-blue-dark;
}

.button {
  min-width: 144px;
  margin-left: 14px;
  font-weight: 400;
}

.buttons {
  display: flex;
  justify-content: center;

  button,
  .addButton {
    width: 175px;
  }
}

.removeButton,
.addButton {
  margin-top: 20px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: 32px;
  border: 1px dashed $color-grey-smooth;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  color: $color-grey-1;
}
