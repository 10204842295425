@import '~app/mixins';

.upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  margin-top: 32px;
  border: 1px dashed $color-grey-smooth;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  color: $color-grey-1;
  padding: 0 20px;
}

.lineContainer {
  .line {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      border-top: 1px dotted $color-grey-lighter;
    }

    .key,
    .value {
      background: $color-alabaster;
      z-index: 1;
      line-height: 20px;
    }

    .key {
      color: $color-grey-1;
      font-size: 14px;
      padding-right: 5px;
      position: relative;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
      position: absolute;
      right: 0;
      top: 0;
      padding-left: 5px;
    }
  }

  .amount {
    font-size: 11px;
    font-style: italic;
    line-height: 13px;
    margin-bottom: 5px;
  }

  &.total {
    .amount {
      display: none;
    }

    .line {
      border-top: 1px solid $color-grey-1;
      margin-top: 10px;
    }
  }
}
