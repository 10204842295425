.clsUserIcon {
    svg {
        fill: #99A0AA;
    }

    &.clsUserIconGreen {
        svg {
            fill: #128292;
            color: #128292;
        }
    }
}
.pb_48{
    padding-bottom: 48px;
}

.mb_16{
    margin-bottom: 16px;
}

.text_primary{
    color: #128292;
}

.clsBtnOrng {
    background-color: #F16722 !important;
    padding: 11px 15px;
            padding: 9px 15px;
  background-color: transparent;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #FFFFFF;
  border: none;
  display: inline-block;
}

.pdSmall{ padding: 0px 10px;}
.clsTMCard {
    margin-top: 16px;
    padding: 32px;
    border-radius: 12px;
    background: #FFF;
    height: 100%;
    width: 303px;
    min-width: 301px;
    max-width: 332px;

    .clsSection1 {

        .clsTxt1 {
            font-size: 36px;
            font-weight: 700;
            line-height: 36px;
            text-align: left;
            color: #66707D;
            margin: 0;
            margin-top: 12px;

        }
        .text_primary{
            color: #128292;
        }
        .clsTxt2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            text-align: left;
            color: #66707D;
            margin: 0;
            margin-top: 4px;
        }
    }

    .clsSection2 {
        margin-top: 120px;

        .clsTxt1 {
            font-size: 36px;
            font-weight: 700;
            line-height: 36px;
            text-align: left;
            color: #3C4654;
            margin: 0;
            margin-bottom: 16px;

            sub {
                font-size: 14px;
                font-weight: 700;
                line-height: 14px;
                text-align: left;
                color: #3C4654;
                bottom: 0;
            }
        }

        .btn_primary {
            padding: 11px 15px;
            width: 100%;
        }
    }

    .clsSection3 {
        margin-top: 32px;

        .clsTxt1 {
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: left;
            color: #3C4654;
            margin: 0;
            margin-bottom: 16px;
        }

        .clsFeatureList {
            padding-left: 14px;
            list-style: none;
            margin: 0;

            li {
                font-size: 14px;
                font-weight: 500;
                line-height: 18.2px;
                text-align: left;
                color: #66707D;
                position: relative;
                margin-bottom: 16px;

                &::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 999px;
                    background-color: #128292;
                    margin: 5px 0;
                    display: block;
                    position: absolute;
                    left: -14px;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.clsTMCreateProfileSec {
    .clsTMCreateProfileHead {
        padding: 15px 0;
        margin-bottom: 16px;

        .clsMainTitle {
            font-size: 36px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            text-align: center;
            margin: 10px 0px;
            color: #3C4654;
        }

        .clsHeadPara {
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            text-align: center;
            color: #66707D;
            margin: 0;
        }
    }

    .clsFormSction {
        margin-bottom: 23px;
    }

    .clsRequiredTxt {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #66707D;
        margin-bottom: 16px;
    }

    .clsFormTitle {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #3C4654;
        margin-bottom: 16px;
    }

    .clsFormGroup {
        padding-bottom: 24px;

        .clsFormLabel {
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            color: #3C4654;
            margin-bottom: 8px;
        }

        .clsFormControl {
            border-radius: 12px;
            border: solid 1px #DFE1E4;
            background-color: #fff;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            text-align: left;
            color: #3C4654;
            box-shadow: none;
            padding: 11px 13px;
            width: 100%;

            &::placeholder {
                color: #99A0AA;
            }
        }

        .clsFormSelect {
            option:first-child {
                color: #99A0AA;
            }

            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 100%;
            background-image: url('assets/images/account-page/down-arrow.svg');
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 12px 12px;
            padding: 13px 13px;
        }

        .clsInputGroup {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            width: 100%;

            .clsFormControl {
                flex: 1;

                &:first-child {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    margin-right: 1px;
                }

                &:last-child {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    margin-left: 1px;
                }
            }
        }
    }

    .clsSelectProfileImg {
        .clsFormLabel {
            margin-bottom: 12px;
            text-align: center;
            display: block;
        }

        .clsAddTxt {
            margin-top: 6px;
        }
    }
}

.clsTMReviewPaySec {
    .clsTMReviewPayCard {
        padding: 24px;
        border-radius: 12px;
        background-color: #FFFFFF;

        .clsTMReviewUserDetils {
            margin-bottom: 16px;

            .clsTMReviewUserImg {
                width: 64px;
                height: 64px;
                border-radius: 999px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .clsTMReviewUserName {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;
                margin: 0;
                margin-left: 10px;
            }
        }

        .clsTMReviewTier {
            padding: 12px 16px;
            border-radius: 12px;
            background-color: #EFF2F6;

            .clsTxtLine1 {
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
                text-align: left;
                color: #66707D;
            }

            .clsTxtLine2 {
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                color: #3C4654;
            }
        }
    }

    .clsTMReviewPayCard2 {
        padding: 0 48px;
        border-radius: 12px;
        background-color: #FFFFFF;

        .clsTMReviewHeading {
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #3C4654;
            margin: 0;
            padding-top: 48px;
        }

        .clsTMReviewPayTimelineContainer {
            padding: 48px 0;

            .clsTMReviewPayTimeline {
                position: relative;
                margin: 16px 0;

                .clsTMTimelineLine {
                    position: absolute;
                    left: 3px;
                    top: 9px;
                    bottom: 10px;
                    width: 2px;
                    background: #128292;
                }

                .clsTMTimelineItem {
                    position: relative;
                    padding-left: 20px;
                    margin-bottom: 64px;

                    &:last-child {
                        margin-bottom: 0;

                        .clsTMTimelineDot {
                            background: #FFFFFF;
                            border: solid 2px #128292;
                        }
                    }

                    .clsTMTimelineDot {
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 8px;
                        height: 8px;
                        background: #128292;
                        border-radius: 50%;
                    }

                    .clsTMTimelineContent {

                        .clsTMTimelineHead {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 8px;

                            .clsTMTimelineLabel {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 16px;
                                text-align: left;
                                color: #3C4654;

                            }

                            .clsTMTimelinePrice {
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 16px;
                                text-align: left;
                                color: #3C4654;
                            }
                        }

                        .clsTMTimelineDescription {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 19.2px;
                            text-align: left;
                            color: #66707D;

                        }
                    }


                }
            }
        }

        .clsTMReviewPMCard {
            background-color: #EFF2F6;
            padding: 24px;
            border-radius: 16px;

            .clsTMReviewPMCardHeading {
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
                text-align: center;
                color: #3C4654;
                margin-bottom: 16px;
            }

            .clsDropdownCard {
                background-color: #DFE1E4;
                border-radius: 12px;
                padding: 2px 20px 2px 2px;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: #BCC1C7;
                }

                &.clsDropdownCardOpen {
                    background-color: #128292;
                }
            }

            .clsPayCard {
                background-color: #FFFFFF;
                border-radius: 12px;
                padding: 20px;
                display: inline-flex;
                border: solid 1px #FFFFFF;
                transition: border-color 0.3s ease;

                .clsCustomCheckbox input[type="checkbox"]:checked~& {
                    border-color: #128292;
                }

                &.clsPayCardFull {
                    display: flex !important;
                }

                .clsMediaTxt1 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    color: #3C4654;
                    margin-bottom: 0;
                }

                .clsMediaTxt2 {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.8px;
                    color: #66707D;
                    margin-bottom: 0;
                    margin-top: 5px;
                }
            }
        }

        .clsTMReviewContentPara {
            padding-bottom: 48px;

            .clsPara {
                font-size: 16px;
                font-weight: 500;
                line-height: 20.8px;
                text-align: left;
                color: #3C4654;
            }

        }

    }
}

.clsBtnOrng {
    background-color: #F16722 !important;
    border-radius: 999px;
      &:hover{
        background-color: #D34F0D !important; 
      }
      &:active{
        background-color: #AB400B !important; 
      }
      &:disabled{
        color: #99A0AA;
        background-color: #DFE1E4 !important; 
      }
  }
  
  .clsInnerFooter {
    padding: 24px;
    background-color: #EFF2F6;
    box-shadow: inset 1px 0 0px 1px #BCC1C7;
    display: flex;
    align-items: center;
    justify-content: center;
  
    button {
      + {
        button {
          margin-left: 24px;
        }
      }
    }
  }