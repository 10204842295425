@import '~app/mixins';

.header {
  display: flex;
  justify-content: space-between;
  padding: 9px 21px 9px 7px;
  border-bottom: 1px solid $color-grey-light;
  min-height: 65px;

  &Search {
    display: flex;
    align-items: flex-end;
    width: 1000px;

    .analysesColumn {
      flex-basis: 34%;
      max-width: 34%;
      margin-left: 30px;
    }

    .propertyColumn {
      flex-basis: 34%;
      max-width: 34%;
    }

    button {
      margin-left: 30px;
    }
  }
}

.buttons {
  margin-right: 25px;
  display: flex;
  align-items: center;

  .buttonExport {
    background: none;
    border: none;
    padding: 0;
    margin: 0 15px;

    &:hover {
      opacity: 0.8;
    }
  }

  .iconExport {
    width: 26px;
    height: 24px;
  }
}

.body {
  display: flex;
  padding: 5px;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;

  &Left,
  &Right {
    flex-grow: 0;
    flex-shrink: 0;
    border: 1px solid $color-grey-light;
    border-radius: 4px;
  }

  &Left {
    flex-grow: 1;
    width: 63.3%;
    margin-right: 5px;
  }

  &Right {
    width: 35.56%;
    border: none;
    background-color: $color-dirty-white;

    > div {
      height: 100%;
    }

    :global {
      .react-tabs {
        height: calc(100% - 50px);
      }

      .react-tabs__tab-panel--selected {
        height: 100%;
        border-radius: 0 0 4px 4px;
        border: 1px solid $color-grey-light;
        border-top: none;
        background: $color-dirty-white;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-top: 1px solid $color-grey-light;

  &Left {
    button {
      min-width: 100px;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &Right {
    button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
