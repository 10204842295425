@use 'sass:math';

@import '~app/mixins';

.tags {
  transition: all $transition-delay;
  position: relative;

  .dropdownButton {
    background: $color-orange !important;


    svg {
      fill: white;
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }
  }

  .dropdown {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: -330px;
    width: 1000px;
    padding: 0 25px 10px;
    color: $color-grey-1;
    background-color: $color-white;
    border: 2px solid transparent;
    box-shadow: $dropdown-shadow;
    font-size: 14px;

    @include primary-border-color();

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      $size: 16px;

      left: 415px;
      margin-left: math.div(-$size, 2);
      top: math.div(-$size, 2);
      width: $size;
      height: $size;
      transform: rotate(45deg);
      border: 2px solid transparent;
      background: $color-white;

      @include primary-border-color();
    }

    &:after {
      $size: 30px;

      top: 0;
      left: 415px;
      margin-left: math.div(-$size, 2);
      width: $size;
      height: math.div($size, 2);
      background: $color-white;
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      padding: 9px 0;
      border-bottom: 2px solid #f0f0f0;

      .caption {
        font-size: 16px;
        line-height: 35px;
        color: $color-blue-dark;
      }

      // todo it's modal duplicate
      button.btnClose {
        $size: 21px;

        position: absolute;
        right: 20px;
        top: 12px;
        width: $size;
        height: $size;
        padding: 0;

        &:hover {
          opacity: 0.8;
        }

        .iconClose {
          width: $size;
          height: $size;
        }
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
    }

    .label {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .section {
      margin-top: 10px;
      margin-bottom: 20px;

      .editCheck {
        color: blue;
        text-decoration: underline;
        cursor: pointer;

        &.selected {
          font-weight: bold;
        }
      }

      .tagEdit {
        display: flex;
        margin-top: 10px;

        input {
          flex: 1;
        }

        button {
          width: auto;
        }
      }
    }

    .sides {
      display: flex;
      justify-content: space-between;

      > div {
        &:first-child {
          width: 44%;
        }

        &:last-child {
          width: 54%;
        }
      }

      .tags {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        max-height: 500px;
        overflow-y: auto;

        .tag {
          width: 50%;
        }
      }

      .users {
        max-height: 600px;
        overflow-y: auto;
      }

      .field {
        width: 100%;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      button {
        width: 100px;
        margin: 0 10px;
      }
    }

    .log {
      padding-top: 20px;
      margin-bottom: 20px;
      border-top: 2px solid #f0f0f0;

      .logs {
        max-height: 200px;
        overflow-y: auto;

        div {
          margin-top: 8px;
        }
      }
    }
  }
}
