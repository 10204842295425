.text_danger {
  color: #CA1551 !important;
}

.text_primary {
  color: #128292 !important;
}


.clsBorderDanger {
  border-color: #CA1551 !important;
}


.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.clsFormGroup {
  .clsFormLabel {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #3C4654;
    margin-bottom: 8px;
  }

  .clsFormControl {
    border-radius: 12px;
    border: solid 1px #DFE1E4;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #3C4654;
    box-shadow: none;
    padding: 11px 13px;
    width: 100%;

    &::placeholder {
      color: #99A0AA;
    }
  }

  .clsFormSelect {
    option:first-child {
      color: #99A0AA;
    }

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    background-image: url('assets/images/account-page/down-arrow.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 12px;
    padding: 13px 13px;
  }

  .clsInputGroup {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .clsFormControl {
      flex: 1;

      &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-right: 1px;
      }

      &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: 1px;
      }
    }
  }

  .clsInputCard {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
}
