@import '~app/mixins';

.fullWidth {
  flex-grow: 1;
}

.ml {
  margin-left: 10px;
}

.moreButton {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.link {
  @include primary-color();
}

.filter {
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.tableContainer {
  position: relative;

  table {
    tbody {
      tr {
        td {
          padding: 2px 5px;

          &.indexCell {
            padding: 0;

            .selectCell {
              display: flex;
              padding: 2px 5px;
              height: 30px;

              &.draggable {
                cursor: grab;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    position: relative;

    .remaining {
      position: absolute;
      left: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .buttons {
      margin: 8px 0;

      button + button {
        margin-left: 10px;
      }
    }
  }

  &.propertySearchTable {
    .colorDot {
      margin-top: 4px;
    }

    .marketing {
      white-space: nowrap;

      svg {
        width: 16px;
        height: 16px;

        + svg {
          margin-left: 4px;
        }
      }
    }

    table {
      td {
        padding: 9px 12px;
      }

      th {
        padding: 3px 6px;
      }

      :global {
        .th-selected {
          width: 30px;
        }

        .th-streetAddress {
          width: 22%;
        }

        .th-listingType {
          width: 85px;
        }

        .th-cityName {
          width: 20%;
        }

        .th-stateCode {
          width: 50px;
        }

        .th-zip {
          width: 50px;
        }

        .th-bedrooms {
          width: 50px;
        }

        .th-bathrooms {
          width: 50px;
        }

        .th-squareFeet {
          width: 80px;
        }

        .th-mlsListingStatus {
          width: 80px;
        }

        .th-marketingFlags {
          width: 80px;
        }

        .th-groupIds {
          width: 150px;
        }
      }
    }
  }

  &.contactSearchTable {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
      white-space: pre-line;
      width: 120px;
      max-width: 120px;
    }

    td:nth-child(6) {
      max-width: 200px;
    }

    td:nth-child(7),
    td:nth-child(8) {
      white-space: pre-line;
    }
  }
}
